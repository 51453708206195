<template>
  <div class="hold-transition">
    <div class="row">
      <div class="form-group col-md-4">
        <label for="gerente_area">Nombre Gerente Area</label>
        <v-select
          :class="[
            $v.gerente_area.$invalid ? 'is-invalid' : 'is-valid',
            $store.getters.getDarkMode ? 'dark-vselect' : '',
          ]"
          v-model="gerente_area"
          placeholder="Gerente Area"
          label="nombres"
          :options="listasForms.gerente"
          class="form-control form-control-sm p-0"
          disabled
          @input="gerenteArea()"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table
          class="
            table table-bordered table-striped table-hover table-sm
            text-xs
          "
        >
          <thead class="bg-gray text-center">
            <tr>
              <th class="text-center" style="width: 10%">Area</th>
              <th class="text-center">OPEX/CAPEX</th>
              <th class="text-center">Dueño AFE</th>
              <th class="text-center">CECO/AFE/G&A</th>
              <th class="text-center">
                % de la operacion Para cargar este CECO/AFE
              </th>
              <th>Activo de Producción</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="(contable, index) in csContables" :key="contable.id">
              <td class="text-center">
                <div
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                >
                  <select
                    id="area"
                    class="form-control form-control-sm col-md-12"
                    v-model="csContable.area_obj"
                    :class="
                      $v.csContable.area_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="area in listasForms.areas"
                      :key="area.numeracion"
                      :value="area"
                    >
                      {{ area.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      contable.area_obj !== undefined &&
                      contable.responsable_obj !== null
                    "
                  >
                    {{ contable.area_obj.descripcion }}
                  </div>
                  <div v-else>
                    {{ contable.nArea }}
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                >
                  <select
                    id="tipo_cargo"
                    class="form-control form-control-sm col-md-12"
                    v-model="csContable.tipo_cargo_obj"
                    :class="
                      $v.csContable.tipo_cargo_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="getTipoCargo()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="opex in listasForms.opex_capex"
                      :key="opex.numeracion"
                      :value="opex"
                    >
                      {{ opex.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      contable.tipo_cargo_obj !== undefined &&
                      contable.tipo_cargo_obj !== null
                    "
                  >
                    {{ contable.tipo_cargo_obj.descripcion }}
                  </div>
                  <div v-else>
                    {{ contable.opexCapex }}
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                >
                  <v-select
                    :class="[
                      $v.csContable.responsable_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="csContable.responsable_obj"
                    placeholder="Responsable"
                    label="funcionario"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.responsables"
                    :filterable="true"
                  ></v-select>
                </div>
                <div v-else>
                  <div
                    class="col-md-12 pl-0"
                    v-if="
                      contable.responsable_obj !== undefined &&
                      contable.responsable_obj !== null
                    "
                  >
                    <input
                      :value="contable.responsable_obj.funcionario"
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 9px"
                      disabled
                    />
                  </div>
                  <div class="col-md-8 pl-0" v-else>
                    <input
                      :value="contable.responsable ? `${contable.responsable.nombres} ${contable.responsable.apellidos}` : ''"
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 9px"
                      disabled
                    />
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  class="row"
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                >
                  <div class="col-md-6 pr-1" v-if="csContable.tipo_cargo_obj">
                    <select
                      placeholder="Ceco AFE"
                      label="nombres"
                      class="form-control form-control-sm p-0"
                      v-model="csContable.tipo_presupuesto"
                      :class="
                        $v.csContable.tipo_presupuesto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="getCecosAfes()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        value="1"
                        :disabled="csContable.tipo_cargo_obj.numeracion != 1"
                      >
                        AFE
                      </option>
                      <option
                        value="2"
                        :disabled="csContable.tipo_cargo_obj.numeracion != 2"
                      >
                        CECO
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 pl-0">
                    <v-select
                      :class="[
                        $v.csContable.cecosAfes_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      class="form-control form-control-sm p-0"
                      style="font-size: 10px"
                      v-model="csContable.cecosAfes_obj"
                      v-show="csContable.tipo_presupuesto"
                      placeholder="CeCo / AFE"
                      label="codigo_contable"
                      :options="listasForms.cecos_afes"
                    ></v-select>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-md-4 pr-1">
                      <span
                        class="badge"
                        :class="
                          contable.tipo_presupuesto == 1 ? 'bg-info' : 'bg-navy'
                        "
                      >
                        {{ contable.tipo_presupuesto == 1 ? "AFE" : "CECO" }}
                      </span>
                    </div>
                    <div
                      class="col-md-8 pl-0"
                      v-if="
                        contable.cecosAfes_obj !== undefined &&
                        contable.cecosAfes_obj !== null
                      "
                    >
                      <input
                        :value="contable.cecosAfes_obj.codigo_contable"
                        type="text"
                        class="form-control form-control-sm"
                        style="font-size: 9px"
                        disabled
                      />
                    </div>
                    <div class="col-md-8 pl-0" v-else>
                      <input
                        :value="contable.ceco_afe ? contable.ceco_afe.codigo_contable : ''"
                        type="text"
                        class="form-control form-control-sm"
                        style="font-size: 9px"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                >
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="porcentaje"
                    v-model="csContable.porcentaje"
                    :class="
                      $v.csContable.porcentaje.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>
                  {{ contable.porcentaje }}
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                >
                  <select
                    id="activo_produccion"
                    class="form-control form-control-sm col-md-12"
                    v-model="csContable.activo_produccion_obj"
                    :class="
                      $v.csContable.activo_produccion_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="activoP in listasForms.activos_produccion"
                      :key="activoP.numeracion"
                      :value="activoP"
                    >
                      {{ activoP.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      contable.activo_produccion_obj !== undefined &&
                      contable.activo_produccion_obj !== null
                    "
                  >
                    {{ contable.activo_produccion_obj.descripcion }}
                  </div>
                  <div v-else>
                    {{ contable.activoProduccion }}
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="estado_contable == 1">
              <td>
                <div class="btn-group float-right">
                  <button
                    class="btn bg-success"
                    v-show="!$v.csContable.$invalid"
                    @click="saveContable()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelContable()">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
              <td>
                <select
                  id="area"
                  class="form-control form-control-sm col-md-12"
                  v-model="csContable.area_obj"
                  :class="
                    $v.csContable.area_obj.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="area in listasForms.areas"
                    :key="area.numeracion"
                    :value="area"
                  >
                    {{ area.descripcion }}
                  </option>
                </select>
              </td>
              <td>
                <select
                  id="tipo_cargo"
                  class="form-control form-control-sm col-md-12"
                  v-model="csContable.tipo_cargo_obj"
                  :class="
                    $v.csContable.tipo_cargo_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="getTipoCargo()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="opex in listasForms.opex_capex"
                    :key="opex.numeracion"
                    :value="opex"
                  >
                    {{ opex.descripcion }}
                  </option>
                </select>
              </td>
              <td>
                <v-select
                  :class="[
                    $v.csContable.responsable_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="csContable.responsable_obj"
                  placeholder="Responsable"
                  label="funcionario"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.responsables"
                  :filterable="true"
                ></v-select>
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 pr-1" v-if="csContable.tipo_cargo_obj">
                    <select
                      placeholder="Ceco AFE"
                      label="nombres"
                      class="form-control form-control-sm p-0"
                      style="font-size: 10px"
                      v-model="csContable.tipo_presupuesto"
                      :class="
                        $v.csContable.tipo_presupuesto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="getCecosAfes()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        value="1"
                        :disabled="csContable.tipo_cargo_obj.numeracion != 1"
                      >
                        AFE
                      </option>
                      <option
                        value="2"
                        :disabled="csContable.tipo_cargo_obj.numeracion != 2"
                      >
                        CECO
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 pl-0">
                    <v-select
                      :class="[
                        $v.csContable.cecosAfes_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      class="form-control form-control-sm p-0"
                      style="font-size: 10px"
                      v-model="csContable.cecosAfes_obj"
                      v-show="csContable.tipo_presupuesto"
                      placeholder="CeCo / AFE"
                      label="codigo_contable"
                      :options="listasForms.cecos_afes"
                    ></v-select>
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="porcentaje"
                  v-model="csContable.porcentaje"
                  :class="
                    $v.csContable.porcentaje.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </td>
              <td>
                <select
                  id="activo_produccion"
                  class="form-control form-control-sm col-md-12"
                  v-model="csContable.activo_produccion_obj"
                  :class="
                    $v.csContable.activo_produccion_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="activoP in listasForms.activos_produccion"
                    :key="activoP.numeracion"
                    :value="activoP"
                  >
                    {{ activoP.descripcion }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "CsSolicitudesInternasPresupuesto",
  components: {
    vSelect,
  },
  data() {
    return {
      gerente_area: null,
      csContables: [],
      csContable: {
        id: null,
        cs_solicitud_interna_id: null,
        area: null,
        area_obj: null,
        tipo_cargo: null,
        tipo_cargo_obj: null,
        cantidad_vh_equi: null,
        tipo_presupuesto: null,
        ceco_afe_id: null,
        responsable_obj: null,
        responsable_id: null,
        porcentaje: null,
        activo_produccion: null,
        activo_produccion_obj: null,
        cecosAfes_obj: null,
      },
      listasForms: {
        tipos_equipos: [],
        areas: [],
        gerente: [],
        activos_produccion: [],
        opex_capex: [],
        responsables: [],
        cecos_afes: [],
      },
      totalPresupuesto: 0,
      estado_contable: -1,
      id_actualizar_contable: -1,
    };
  },
  validations: {
    csContable: {
      area_obj: {
        required,
      },
      tipo_cargo_obj: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      responsable_obj: {
        required,
      },
      porcentaje: {
        required,
      },
      activo_produccion_obj: {
        required,
      },
      cecosAfes_obj: {
        required,
      },
    },
    gerente_area: {
      required,
    },
  },

  methods: {
    async getCsContable() {
      await axios
        .get("/api/cs/asignacionesSolicitudesInternas/solicitudesInternasPresupuesto", {
          params: { solicitudInterna_id: this.$parent.form.id },
        })
        .then(async (response) => {
          this.csContables = response.data;
          if (
            this.$route.params.accion == "Editar" &&
            this.$route.params.data_edit.gerente_area
          ) {
            this.gerente_area = this.$route.params.data_edit.gerente_area;
          }
          await this.getCecosAfes(response.data.tipo_presupuesto);
          await this.getGerente();
          await this.llenarContable();
        });
    },

    async gerenteArea() {
      this.$parent.form.gerente_area_id = this.gerente_area.id;
    },

    async getTipoEquipos() {
      await axios.get("/api/lista/129").then((response) => {
        this.listasForms.tipos_equipos = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    getActivoProduccion() {
      axios.get("/api/lista/137").then((response) => {
        this.listasForms.activos_produccion = response.data;
      });
    },

    getOpexCapex() {
      axios.get("/api/lista/132").then((response) => {
        this.listasForms.opex_capex = response.data;
      });
    },

    getResponsables() {
      axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.responsables = response.data;
      });
    },

    getTipoCargo() {
      this.csContable.tipo_presupuesto = null;
      this.csContable.cecosAfes_obj = null;
    },

    async getGerente() {
      await axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            gerente_area: 1,
            area: this.$parent.user.funcionario[0].area,
          },
        })
        .then((response) => {
          this.listasForms.gerente = response.data;
        });
    },

    async getCecosAfes(tipoP = null) {
      this.listasForms.cecos_afes = [];
      if (tipoP == null) {
        if (this.csContable.tipo_presupuesto == "") {
          this.csContable.tipo_cargo_obj = null;
        }
        tipoP = this.csContable.tipo_presupuesto;
      }
      await axios
        .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
          params: {
            tipo_presupuesto: tipoP,
          },
        })
        .then((response) => {
          this.listasForms.cecos_afes = response.data;
        });
    },

    async llenarContable() {
      await this.csContables.forEach(async (element) => {
        element.area_obj = await this.listasForms.areas.filter((item) => {
          if (item.numeracion == element.area) {
            return item;
          }
        })[0];

        element.tipo_cargo_obj = await this.listasForms.opex_capex.filter(
          (item) => {
            if (item.numeracion == element.tipo_cargo) {
              return item;
            }
          }
        )[0];

        let responsable = {
          id: element.responsable.id,
          funcionario:
            element.responsable.nombres +
            " " +
            element.responsable.apellidos,
          user_id: element.responsable.user_id,
        };
        element.responsable_obj = responsable;

        element.activo_produccion_obj =
          await this.listasForms.activos_produccion.filter((item) => {
            if (item.numeracion == element.activo_produccion) {
              return item;
            }
          })[0];

        element.cecosAfes_obj = await this.listasForms.cecos_afes.filter(
          (item) => {
            if (item.id == element.ceco_afe.id) {
              return item;
            }
          }
        )[0];
      });
    },
  },

  async mounted() {
    await this.getTipoEquipos();
    await this.getAreas();
    await this.getActivoProduccion();
    await this.getOpexCapex();
    await this.getResponsables();
  },
};
</script>
  