<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <p class="mb-0 ml-2" style="font-size: 18px">
                  SOLICITUD CARGA SECA POR LLAMADO DE ACTIVOS DE PRODUCCIÓN
                </p>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga seca</li>
                  <li class="breadcrumb-item active">Internas</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">Formulario Editar</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Formulario Editar Solicitudes
                <span
                  class="badge"
                  :class="
                    form.estado == 1
                      ? 'badge-warning'
                      : form.estado == 2
                      ? 'badge-success'
                      : form.estado == 3
                      ? 'badge-primary'
                      : form.estado == 4
                      ? 'badge-danger'
                      : form.estado == 5
                      ? 'badge-primary'
                      : form.estado == 6
                      ? 'badge-success'
                      : 'badge-danger'
                  "
                  >{{ form.nEstado }}</span
                >
              </legend>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="user">Usuario</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="user"
                    v-model="form.user.name"
                    :class="$v.form.user.$invalid ? 'is-invalid' : 'is-valid'"
                    disabled
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="correo">Correo</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="correo"
                    v-model="form.user.email"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="celular">Celular</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="celular"
                    v-model="form.user.cel"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="tipo_solicitud">Tipo solicitud</label>
                  <select
                    id="tipo_solicitud"
                    class="form-control form-control-sm col-md-12"
                    v-model="form.tipo_solicitud"
                    :class="
                      $v.form.tipo_solicitud.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="modalEmergencia()"
                    disabled
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_solicitud in listasForms.tipos_solicitudes"
                      :key="tipo_solicitud.numeracion"
                      :value="tipo_solicitud.numeracion"
                    >
                      {{ tipo_solicitud.descripcion }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-group col-md-3"
                  v-if="form.tipo_solicitud == 2"
                >
                  <label for="tipo_emergencia">Tipo Emergencia</label>
                  <select
                    id="tipo_emergencia"
                    class="form-control form-control-sm col-md-12"
                    v-model="form.tipo_emergencia"
                    :class="
                      $v.form.tipo_emergencia.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="form.estado == 2 || form.id"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_emergencia in listasForms.tipos_emergencias"
                      :key="tipo_emergencia.numeracion"
                      :value="tipo_emergencia.numeracion"
                    >
                      {{ tipo_emergencia.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <label for="clase_solicitud">Clase de Solicitud</label>
                  <select
                    id="clase_solicitud"
                    class="form-control form-control-sm col-md-12"
                    v-model="form.clase_solicitud"
                    v-on:input="$v.form.clase_solicitud.$touch"
                    :class="
                      $v.form.clase_solicitud.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="clase_solicitud in listasForms.clases_solicitudes"
                      :key="clase_solicitud.numeracion"
                      :value="clase_solicitud.numeracion"
                    >
                      {{ clase_solicitud.descripcion }}
                    </option>
                  </select>
                  <div
                    v-if="
                      (!$v.form.clase_solicitud.$dirty ||
                        $v.form.clase_solicitud.$invalid) &&
                        form.clase_solicitud == null
                    "
                  >
                    <span class="text-danger"
                      ><small>*Campo requerido</small></span
                    >
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label>Bloque</label>
                  <v-select
                    v-model="form.bloque"
                    placeholder="Nombre"
                    label="nombre"
                    :options="listasForms.bloques"
                    @input="seleccionarBloque()"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.bloque_id.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  ></v-select>
                </div>
                <div class="form-group col-md-3">
                  <label>Sitio</label>
                  <v-select
                    v-model="form.sitio"
                    placeholder="Nombre"
                    disabled
                    label="nombre"
                    :options="listasForms.sitios"
                    @input="seleccionarSitio()"
                    class="form-control form-control-sm p-0"
                    :class="
                      $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  ></v-select>
                </div>
                <div class="form-group col-md-3">
                  <label for="fecha">Fecha</label>
                  <input
                    type="date"
                    :min="hoy"
                    class="form-control form-control-sm"
                    id="fecha"
                    v-model="form.fecha"
                    :class="$v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'"
                    disabled
                  />
                </div>
                <div class="form-group col-md-3">
                  <mark
                    v-if="form.presupuesto_verificado == null"
                    ><label class="text-danger">Presupuesto Sin Verificar</label></mark
                  >
                  <label v-else
                    >Presupuesto
                    <span
                      class="badge"
                      :class="
                        form.presupuesto_verificado == true
                          ? 'badge-success'
                          : form.presupuesto_verificado == false
                          ? 'badge-warning'
                          : ''
                      "
                      >{{
                        form.presupuesto_verificado == true
                          ? "Verificado"
                          : "Rechazado"
                      }}</span
                    >
                  </label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="presupuesto_verificado"
                      id="verificacion_si"
                      value="1"
                      v-model="form.presupuesto_verificado"
                      :disabled="verificado == 1"
                    />
                    <label class="form-check-label" for="verificacion_si">
                      Si
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      checked
                      class="form-check-input"
                      type="radio"
                      name="presupuesto_verificado"
                      id="verificacion_no"
                      value="0"
                      v-model="form.presupuesto_verificado"
                      :disabled="verificado == 1"
                    />
                    <label class="form-check-label" for="verificacion_no">
                      No
                    </label>
                  </div>
                </div>
                <div
                  class="form-group col-md-12"
                  v-show="form.tipo_solicitud == 2"
                >
                  <label for="archivo_emergencia"
                    >Cargar certificado de solicitud de emergencia
                  </label>
                  <div v-if="form.archivo_emergencia == null">
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      class="form-control-file"
                      accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                      @change="elegirDirectorio()"
                      disabled
                    />
                  </div>
                  <div v-else>
                    <button
                      type="button"
                      class="btn btn-light col-md-1"
                      @click="getArchivo(form.archivo_emergencia)"
                      v-show="form.archivo_emergencia !== null"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 text-center" style="font-size: 12px">
                      <b>
                        "Su solicitud se gestionará en el menor tiempo posible.
                        Esto dependerá de la disponibilidad y ubicación de los
                        vehículos o equipos por parte de los contratistas, los
                        tiempos para este proceso podran tomarse entre 6, 12, 18
                        ó 24 horas."
                      </b>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="observaciones">Observaciones</label>
                  <textarea
                    class="form-control form-control-sm"
                    v-model="form.observaciones"
                    :class="
                      $v.form.observaciones.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  >
                  </textarea>
                </div>
                <div class="form-group col-md-6">
                  <label for="justificacion">Justificación</label>
                  <textarea
                    class="form-control form-control-sm"
                    id="justificacion"
                    v-model="form.justificacion"
                    :class="
                      $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  >
                  </textarea>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <button class="btn bg-secondary ml-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset
              class="well card"
              v-show="
                form.id != null &&
                  ((form.clase_solicitud == 1 && !userRol) ||
                    form.clase_solicitud == 2)
              "
            >
              <legend class="well-legend text-bold bg-frontera text-light">
                Ruta
              </legend>
              <CsAsignacionesRutas ref="CsAsignacionesRutas" />
            </fieldset>
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Detalle Solicitud
              </legend>
              <CsAsignacionesDetSolicitud ref="CsAsignacionesDetSolicitud" />
            </fieldset>
            <fieldset class="well card" v-show="form.clase_solicitud == 2">
              <legend class="well-legend text-bold bg-frontera text-light">
                Cargos Contables
              </legend>
              <CsAsignacionesPresupuesto ref="CsAsignacionesPresupuesto" />
            </fieldset>
          </div>
          <div class="row justify-content-center">
            <div class="form-group col-md-2 text-center">
              <button
                class="btn bg-frontera text-white"
                v-show="form.id"
                v-b-popover.hover.top="''"
                :title="
                  form.presupuesto_verificado == false || form.presupuesto_verificado == null
                  ? 'El presupuesto no se encuentra verificado'
                  : $refs.CsAsignacionesDetSolicitud.detSolicitudes.some((detalle) => detalle.empresa_id == null)
                  ? 'Por favor asignar empresa en el detalle de la solicitud'
                  : ''
                "
                @click="saveTotal()"
              >
                <i
                  class="fas fa-paper-plane"
                  style="font-size: 1.6em"
                ></i>
                <div>Enviar</div>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import axios from "axios";
import CsAsignacionesRutas from "./CsAsignacionesRutas";
import CsAsignacionesDetSolicitud from "./CsAsignacionesDetSolicitud";
import CsAsignacionesPresupuesto from "./CsAsignacionesPresupuesto";
import vSelect from "vue-select";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "CsAsignacionesForm",

  directives: {
    "b-popover": VBPopover,
  },

  components: {
    Loading,
    vSelect,
    CsAsignacionesRutas,
    CsAsignacionesDetSolicitud,
    CsAsignacionesPresupuesto,
  },

  data() {
    return {
      cargando: false,
      user: {},
      file: null,
      metodo: "",
      userRol: false,
      hoy: moment().format("YYYY-MM-DD"),
      form: {
        id: null,
        user_id: null,
        user: {},
        tipo_solicitud: null,
        tipo_emergencia: null,
        clase_solicitud: null,
        bloque: null,
        sitio: null,
        sitio_id: null,
        bloque_id: null,
        fecha: null,
        observaciones: null,
        justificacion: null,
        estado: null,
        gerente_area_id: null,
        archivo_emergencia: null,
      },
      verificado: 0,
      listasForms: {
        tipos_solicitudes: [],
        tipos_emergencias: [],
        sitios: [],
        bloques: [],
      },
      guardar: 0,
      message: "",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let form = {};
    let viceprecidente = {};
    let archivo = {};
    let tipo_emer = {};
    if (this.form.tipo_solicitud == 2) {
      if (this.form.tipo_emergencia == 2) {
        viceprecidente = {
          required,
        };

        archivo = {
          required,
        };

        tipo_emer = {
          required,
        };
      } else {
        viceprecidente = {
          required: false,
        };

        archivo = {
          required: false,
        };

        tipo_emer = {
          required,
        };
      }
    } else {
      viceprecidente = {
        required: false,
      };

      archivo = {
        required: false,
      };

      tipo_emer = {
        required: false,
      };
    }

    form = {
      user: {
        required,
      },

      fecha: {
        required,
      },

      bloque_id: {
        required,
      },

      sitio_id: {
        required,
      },

      tipo_solicitud: {
        required,
      },

      clase_solicitud: {
        required,
      },

      tipo_emergencia: tipo_emer,
      viceprecidente_id: viceprecidente,
      get_archivo: archivo,

      observaciones: {
        required,
      },

      justificacion: {
        required,
      },
    };

    return {
      form: form,
    };
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.userRoles();
      this.getUser();
      this.metodo = "PUT";

      if (this.$route.params.data_edit) {
        this.form = this.$route.params.data_edit;
        this.form.user = this.$route.params.data_edit.user;
        this.form.user_id = this.$route.params.data_edit.user.id;
        if (this.form.presupuesto_verificado != null) {
          this.verificado = 1;
        }
      }

      await this.$refs.CsAsignacionesRutas.getRutas();
      await this.$refs.CsAsignacionesDetSolicitud.getDetSolicitudes();
      await this.$refs.CsAsignacionesPresupuesto.getCsContable();
      this.cargando = false;
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    modalEmergencia() {
      if (this.form.tipo_solicitud == 2) {
        this.$swal({
          icon: "info",
          html: `<h4>Recuerde, si selecciona Emergencia Operacional la Solicitud se enviara a la <strong>Vicepresidencia de Operaciones.</strong></h4>
          <p style= "color: red">*Nota: Por favor adjuntar la declaración y aprobación de la emergencia de su vicepresidente, de lo contrario su solicitud no será tratada.</p>
          <p>Recuerde que si cancela su solicitud por cualquier motivo se deberá informar inmediatamente, de no hacerlo, esto le generará un costo adicional debido al alistamiento y posibles movilizaciones de los equipos.</p>
          `,
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
        });
      }
    },

    getTipoSolicitud() {
      axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    getTipoEmergencia() {
      axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipos_emergencias = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        if (this.form.presupuesto_verificado == 0) {
          this.form.estado = 3;
        }
        axios({
          method: this.metodo,
          url: "/api/cs/asignacionesSolicitudesInternasSave",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.form = response.data;
            this.form.user = this.$route.params.data_edit.user;
            if (this.form.presupuesto_verificado != null) {
              this.verificado = 1;
            }
            this.$swal({
              icon: "success",
              title: "Se guardo la solicitud Interna...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.back();
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async saveTotal() {
      if (this.$refs.CsAsignacionesDetSolicitud.detSolicitudes.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos del etalle de la solicitud. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else if (this.form.presupuesto_verificado == null) {
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha verificado el presupuesto de la solicitud. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else if (
        this.$refs.CsAsignacionesDetSolicitud.detSolicitudes.some(
          (detalle) => detalle.empresa_id == null
        ) &&
        this.form.presupuesto_verificado == 1
      ) {
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha asignado la empresa al detalle de la solicitud. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else {
        this.cargando = true;
        await this.$refs.CsAsignacionesDetSolicitud.saveDetSolicitudTotal();
        await this.save();
        this.cargando = false;
      }
    },

    getTipoClase() {
      axios.get("/api/lista/183").then((response) => {
        this.listasForms.clases_solicitudes = response.data;
      });
    },

    getSitios() {
      axios
        .get("/api/admin/sitios/lista", {
          params: { bloque_id: this.form.bloque_id },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        });
    },

    seleccionarSitio() {
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      } else {
        this.form.sitio_id = null;
      }
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    seleccionarBloque() {
      if (this.form.bloque) {
        this.form.bloque_id = this.form.bloque.id;
        this.getSitios();
      } else {
        this.form.bloque_id = null;
      }
    },

    getUser() {
      axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
        this.form.user_id = this.user.id;
        this.form.nombre = this.user.name;
        this.form.identificacion = this.user.n_document;
        this.form.celular = this.user.cel;
        this.form.area = this.user.funcionario ? this.user.funcionario[0].nArea : null;
      });
    },

    back() {
      return this.$router.replace("/Cs/AsignacionesInternos");
    },

    elegirDirectorio() {
      this.file = this.$refs.file.files[0];
    },

    eliminarLink() {
      this.file = null;
      this.form.archivo_emergencia = null;
    },

    getArchivo(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    userRoles() {
      this.userRol = false;
      let usuario = this.$store.state.user;
      for (let i = 0; i < usuario.roles.length; i++) {
        let roles = usuario.roles[i];
        switch (roles.id) {
          case 1:
            this.userRol = true;
            break;
          default:
            break;
        }
      }
    },
  },

  beforeMount(){
    this.getTipoSolicitud();
    this.getTipoEmergencia();
    this.getTipoClase();
    this.getBloques();
  },

  mounted() {
    this.getIndex();
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
